<script>
import {appName} from "scaling-garbanzo/src/environment/environment";

export default {
  data:() => ({
    showPassword: false,
    showConfirmationPassword: false,
    formData:{
      password: '',
      password_confirmation: '',
      token: ''
    }
  }),
  mounted() {
    //
    this.formData.token = this.$route.params.code
    //
    const password = document.getElementById("password-input")
    password.onchange = this.validatePassword;
    document.querySelectorAll("form .auth-pass-inputgroup")
        .forEach(function (item) {
          item.querySelectorAll(".password-addon").forEach(function (subitem) {
            subitem.addEventListener("click", function () {
              var passwordInput = item.querySelector(".password-input");
              if (passwordInput.type === "password") {
                passwordInput.type = "text";
              } else {
                passwordInput.type = "password";
              }
            });
          });
        });



    const myInput = document.getElementById("password-input");
    const letter = document.getElementById("pass-lower");
    const capital = document.getElementById("pass-upper");
    const number = document.getElementById("pass-number");
    const length = document.getElementById("pass-length");

    // When the user clicks on the password field, show the message box
    myInput.onfocus = function () {
      document.getElementById("password-contain").style.display = "block";
    };

    // When the user clicks outside of the password field, hide the password-contain box
    myInput.onblur = function () {
      document.getElementById("password-contain").style.display = "none";
    };

    // When the user starts to type something inside the password field
    myInput.onkeyup = function () {
      // Validate lowercase letters
      const lowerCaseLetters = /[a-z]/g;
      if (myInput.value.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
      }

      // Validate capital letters
      const upperCaseLetters = /[A-Z]/g;
      if (myInput.value.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
      }

      // Validate numbers
      const numbers = /[0-9]/g;
      if (myInput.value.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
      }

      // Validate length
      if (myInput.value.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
      }
    };
  },
  computed:{
    submitting(){
      return this.$store.getters['Auth/AuthGetter']('submitting')
    }
  },
  methods: {
    appName() {
      return appName
    },
    validatePassword() {
      // password match
      const password = document.getElementById("password-input"),
          confirm_password = document.getElementById("confirm-password-input");

      //Password validation

      if (password.value !== confirm_password.value) {
        confirm_password.setCustomValidity("Passwords Don't Match");
      } else {
        this.resetPassword()
        confirm_password.setCustomValidity("");
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    toggleConfirmationPassword() {
      this.showConfirmationPassword = !this.showConfirmationPassword
    },
    resetPassword() {
      this.$store.dispatch('Auth/resetPassword', this.formData)
    }
  },
}
</script>

<template>
  <!-- auth-page wrapper -->
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row justify-content-center g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <a href="/" class="d-block">
                          <img src="https://helpdesk.solutechlabs.com/img/logo.334732de.png" alt="" height="60">
                        </a>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i class="ri-double-quotes-l display-4 text-success"></i>
                        </div>

                        <div id="qoutescarouselIndicators" class="carousel slide"
                             data-bs-ride="carousel">
                          <div class="carousel-indicators">
                            <button type="button" data-bs-target="#qoutescarouselIndicators"
                                    data-bs-slide-to="0" class="active" aria-current="true"
                                    aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators"
                                    data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators"
                                    data-bs-slide-to="2" aria-label="Slide 3"></button>
                          </div>
                          <div class="carousel-inner text-center text-white pb-5">
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">" Solutech is a behemoth in the sales automation industry, providing cutting-edge solutions to businesses of all sizes. "</p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">" With its innovative technologies and world-class team, Solutech is a force to be reckoned with in the sales automation landscape.."</p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">" Solutech's impressive track record of success and industry-leading expertise make it one of the most influential players in the sales automation market. "</p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <h5 class="text-primary">Create new password</h5>
                    <p class="text-muted">Your new password must be different from previous used
                      password.</p>

                    <div class="p-2">
                      <form>
                        <div class="mb-3">
                          <label class="form-label" for="password-input">Password</label>
                          <div class="position-relative auth-pass-inputgroup">
                            <input :type="showPassword?'text':'password'" class="form-control pe-5 password-input"
                                   onpaste="return false" placeholder="Enter password"
                                   id="password-input" aria-describedby="passwordInput"
                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                   required
                                   v-model="formData.password"
                            >
                            <button
                                @click="togglePassword"
                                class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button" id="password-addon"><i
                                class="ri-eye-fill align-middle"></i></button>
                          </div>
                          <div id="passwordInput" class="form-text">Must be at least 8
                            characters.</div>
                        </div>

                        <div class="mb-3">
                          <label class="form-label" for="confirm-password-input">Confirm
                            Password</label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input :type="showConfirmationPassword?'text':'password'" class="form-control pe-5 password-input"
                                   onpaste="return false" placeholder="Confirm password"
                                   pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                   id="confirm-password-input"
                                   required
                                   v-model="formData.password_confirmation"
                            >
                            <button
                                @click="toggleConfirmationPassword"
                                class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                type="button" id="confirm-password-input"><i
                                class="ri-eye-fill align-middle"></i></button>
                          </div>
                        </div>

                        <div id="password-contain" class="p-3 bg-light mb-2 rounded">
                          <h5 class="fs-13">Password must contain:</h5>
                          <p id="pass-length" class="invalid fs-12 mb-2">Minimum <b>8
                            characters</b></p>
                          <p id="pass-lower" class="invalid fs-12 mb-2">At <b>lowercase</b>
                            letter (a-z)</p>
                          <p id="pass-upper" class="invalid fs-12 mb-2">At least
                            <b>uppercase</b> letter (A-Z)</p>
                          <p id="pass-number" class="invalid fs-12 mb-0">A least <b>number</b>
                            (0-9)</p>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value=""
                                 id="auth-remember-check">
                          <label class="form-check-label" for="auth-remember-check">Remember
                            me</label>
                        </div>

                        <div class="mt-4">
                          <button v-if="submitting" class="btn btn-danger w-100" type="button">Processing...Please wait</button>
                          <button v-if="!submitting" class="btn btn-success w-100" @click="validatePassword" type="button">Reset Password</button>
                        </div>

                      </form>
                    </div>

                    <div class="mt-5 text-center">
                      <p class="mb-0">Wait, I remember my password... <a href="/auth/login"
                                                                         class="fw-semibold text-primary text-decoration-underline"> Click
                        here </a> </p>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">&copy; {{ new Date().getFullYear() }} {{ appName() }}. Crafted with <i
                  class="mdi mdi-heart text-danger"></i> by {{ appName() }} Limited.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>